.h_div {
        display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10%;
}

.err_img{
width: auto;
}

@media (max-width: 800px){
    .h_div {
        display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.err_img{
    width: 90%;
    height: 300px;
    }
    
  }

  b {
    cursor: pointer;
  }