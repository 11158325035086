.loader_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5); /* Transparent background */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050; /* Ensure it overlays other elements */
}

.loader {
  /* border: 16px solid #f3f3f3;  */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

p {
  margin-top: 16px;
  color: white;
  font-size: 1.2em;
  text-align: center;
}
