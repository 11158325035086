/* components/Header.module.css */

/* .nav {
  padding: 12px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(255, 226, 226);
  position: sticky;
  height: 100%;
  border-bottom: 2px solid gray;
} */
.nav {
  padding: 12px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(255, 255, 255);
  position: sticky;
  top: 0; /* Ensure it sticks to the top of the viewport */
  height: 60px; /* Adjust height as needed */
  z-index: 1000; /* Ensure it's above other content */
  /* border-bottom: 2px solid gray; */
}

.logo {
  /* border: 2px solid black; */
  display: flex;
}

.menuList {
  list-style-type: none;
  display: flex;
  gap: 30px;
  align-items: center;
}

.menuList li {
  list-style-type: none;
}

.menuList li a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.menuList li a:hover {
  color: blue;
  text-decoration: underline;
}

.menuIcon {
  display: none;
  color: black;
}

.menuIcon:hover{
  cursor: pointer;
}

.menuIcon i {
  color: #fff;
  font-size: 30px;
}

@media (max-width: 700px) {
  .menuList {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    flex-direction: column;
    text-align: center;
    background: #f4f9f9;
    gap: 0;
    overflow: hidden;
    transition: all 0.5s;
    box-shadow: 0px 0px 11px -3px rgba(0, 0, 0, 0.36);
    /* display: none; */
    padding: 20px;
  }

  .menuList li {
    padding: 20px;
    padding-top: 0;
  }

  .menuList li:hover{
    transform: scaleX(1.5);
  }


  .menuIcon {
    display: block;
  }

  .menuList.open {
    transition: all 0.5s;
  }

  .menuList.show {
    transition: all 0.5s;
    visibility: hidden;
  }

  .menuList.not {
    visibility: visible;
  }
}

.menuList_bkp.show_our{
  color: blue;
  font-size: 20px;
  text-decoration: underline;
}

.menuList_bkp.not_our{
  color: black;
}

/* -----------------footer--------- */
.text_decoration {
  color: black;
  text-decoration: none;
}

.text_decoration:hover {
  text-decoration: underline;
}

.StyledHeader {
  display: flex;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.16);
  /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
  rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
  position: sticky;
  z-index: 100;
  height: 70px;
  top: 0;
}

.Wrapper {
  height: auto;
  width: 90%;
  /* display: flex; */
  justify-content: space-between;
  /* padding: 20px 0; */
}




/* ----------------------btn------------------- */
.StyledDiv {
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  /* border:2px solid black; */
  display: flex;
  flex-direction: column;
  /* padding: 0 30px; */
  /* // background:#fff; */
}

.Second {
  padding: 5px 25px;
  font-weight: bold;
  font-size: 16px;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  cursor: pointer;
}
.test_clas {
  display: flex;
  gap: 40px;
  justify-content: center;
  align-items: center;
  margin: auto;
  border: 10px solid black;
  height: 100px;
  background: rgb(197, 16, 16);
}


.profile-dropdown {
  position: relative;
}

.profile-dropdown-toggle { 
  border: none; 
  cursor: pointer;
  width: 3rem;
}

.profile-dropdown-menu {
  background-color: #e8f8ff;
  border-radius: 5px;
  border: 1px solid gray;
  position: absolute;
  top: 100%; 
  list-style: none;
  padding: 10px;
  margin-top: 5px;  
}
.profile-dropdown-menu li{
  margin-top: 10px;
}