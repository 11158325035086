footer {
  /* background-color: transparent; */
  background-color: aqua;

}

.content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
}

.list-style {
  list-style-type: none;
  padding: 0;
  color: rgb(100, 98, 98);
}

a {
  color: grey;
}

.company a, .resources a, .footer-links a {
  color: gray;
  font-size: 16px;
  line-height: 2.2;
}

.company a:hover, .resources a:hover, .footer-links a:hover {
  color: rgb(58, 53, 94);
  cursor: pointer;
}

.hover-underline {
  position: relative;
  text-decoration: none;
  font-size: 12px;
}

.hover-underline::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: rgb(53, 53, 80); /* Change this to the color you want for the underline */
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.hover-underline:hover::after {
  transform: scaleX(1);
}

img {
  margin-left: 33px;
}

@media (max-width: 550px) {
  .content-container {
      flex-direction: column;
  }
  .company, .resources, .footer-links {
      width: 100%;
      text-align: center;
      margin-top: 20px;
  }
}
